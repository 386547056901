@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  background-color: #000000;
  color: #ffffff;
}

@font-face {
  font-family: "sfproregular";

  src: url("./fonts/SF-Pro-Display-Regular.otf");
}

.sfproregular {
  font-family: "sfproregular";
}

@font-face {
  font-family: "sfprobold";
  src: url("./fonts/SF-Pro-Display-Bold.otf");
}

.sfprobold {
  font-family: "sfprobold";
}

@font-face {
  font-family: "sfpromedium";
  src: url("./fonts/SF-Pro-Display-Medium.otf");
}

.sfpromedium {
  font-family: "sfpromedium";
}

@font-face {
  font-family: "sfprosemibold";
  src: url("./fonts/SF-Pro-Display-Semibold.otf");
}

.sfprosemibold {
  font-family: "sfprosemibold";
}

@font-face {
  font-family: "sfproblack";
  src: url("./fonts/SF-Pro-Display-Black.otf");
}

.sfproblack {
  font-family: "sfproblack";
}

@font-face {
  font-family: "sfproblackitalic";
  src: url("./fonts/SF-Pro-Display-BlackItalic.otf");
}

.fontproblackitalic {
  font-family: "sfproblackitalic";
}

@font-face {
  font-family: "sfprobolditalic";
  src: url("./fonts/SF-Pro-Display-BoldItalic.otf");
}

.sfprobolditalic {
  font-family: "sfprobolditalic";
}

@font-face {
  font-family: "sfprolight";
  src: url("./fonts/SF-Pro-Display-Light.otf");
}

.sfprolight {
  font-family: "sfprolight";
}

@font-face {
  font-family: "sfprolightitalic";
  src: url("./fonts/SF-Pro-Display-LightItalic.otf");
}

.sfprolightitalic {
  font-family: "sfprolightitalic";
}
@font-face {
  font-family: "sfprothin";
  src: url("./fonts/SF-Pro-Display-Thin.otf");
}

.sfprothin {
  font-family: "sfprothin";
}

@font-face {
  font-family: "sfproultralight";
  src: url("./fonts/SF-Pro-Display-Ultralight.otf");
}

.sfproultralight {
  font-family: "sfproultralight";
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.animate {
  animation: animate 0.6s cubic-bezier(0.2, 0.8, 0.2, 1);
  animation-fill-mode: backwards;
}

.animate2 {
  animation: animate 1s cubic-bezier(0.4, 0.8, 0.2, 1);
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: translateY(30vh);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes animate2 {
  0% {
    opacity: 0;
    transform: translateY(30vh);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.on-hover-40 {
  transition: all 0.4s cubic-bezier(0.2, 0.6, 0.2, 1);
}

.navi {
}

.navi:hover span {
  display: none;
}

.navi:hover:before {
  content: "About!";
}
.navi2 {
}

.navi2:hover span {
  display: none;
}

.navi2:hover:before {
  content: "Work";
}

.navi3 {
}

.navi3:hover span {
  display: none;
}

.navi3:hover:before {
  content: "Contact";
}
